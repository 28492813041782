import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"ma-1",attrs:{"raised":""}},[_c(VRow,[_c(VCol,{attrs:{"cols":"10"}},[_c(VCardTitle,[_vm._v(_vm._s(_vm.record.main))]),_c(VCardText,[_vm._v(_vm._s(`${_vm.record.convent.abbr} (${_vm.dateToLocaleEST(_vm.record.date)}) @${_vm.record.location}`))])],1),_c(VCol,[_c(VCardActions,[_c(VSpacer,{staticClass:"mx-auto"}),_c(VBtn,{staticClass:"mr-3",attrs:{"outlined":"","text":""},on:{"dblclick":_vm.deleteRecord}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }