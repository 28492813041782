<template>
  <div>
    <v-snackbar
        v-for="(message, i) in messages.filter(m => m.open)"
        v-model="message.open"
        :key="message.text + Math.random()"
        :style="`bottom: ${i * 60 + 8}px`"
        :color="message.type"
        :timeout="message.timeout"
        :multi-line="false"
        min-width="50%"
    >
      {{ message.text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="message.open = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: { messages: Array },
  data: () => ({}),
  computed: {},
  methods: {}
};
</script>
