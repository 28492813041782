let required = () => {
  return v => (v && v.length > 0) || `See väli on kohustuslik`
}
let minLength = (property, minLength) => {
  return v => (v && v.length >= minLength) || `${property} peab olema vähemalt ${minLength} tähemärki`
}
let maxLength = (property, maxLength) => {
  return v => (v && v.length <= maxLength) || `${property} peab olema vähem kui ${maxLength} tähemärki`
}
let emailFormat = () => {
  let regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,24})+$/
  return v => (v && regex.test(v)) || 'See ei ole korralik meili aadress'
}

export default { required, minLength, maxLength, emailFormat }
