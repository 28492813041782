export default {
  namespaced: true,
  state: {
    messages: []
  },
  getters: {},
  actions: {
    setMessage({ commit }, message) {
      message.open = true;
      message.type = message.type || "info";
      message.timeout = message.timeout || 2000;
      commit("SET_MESSAGE", message);
    },
    emptyStore({ commit }) {
      commit("EMPTY_STORE");
    }
  },
  mutations: {
    SET_MESSAGE: (state, message) => state.messages.push(message),
    EMPTY_STORE: state => (state.messages = [])
  }
};
