import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,{attrs:{"justify":"center"}},[_c(VCol,{staticClass:"col-xs-12 col-md-6 text-xs-center display-1 mx-3"},[_c(VCard,{attrs:{"light":""}},[_c(VCardTitle,{staticClass:"headline justify-center text-uppercase"},[_vm._v(" About Transcribe "),_c('a',{attrs:{"href":"https://github.com/jg-karl"}},[_c('img',{staticClass:"mx-4 mt-2",attrs:{"src":require("../assets/jgk.png"),"target":"_blank","id":"logo","height":"30px"}})])]),_c(VCardTitle,{staticClass:"subheading justify-center"},[_vm._v(" VueJS app with Vuex, Vuetify, Express and MongoDB ")]),_c(VCardText,{staticClass:"justify-center"},[_vm._v(" VueJS application with NodeJS and MongoDb for transcribing protocols books to preserve them in database format. Initiative is taken for a better usability of this information. Futher information in Estonian locale. ")]),_c(VCardActions,[_c(VRow,{staticClass:"justify-center"},[_c(VBtn,{staticClass:"ma-1",attrs:{"to":{name:'register'}}},[_vm._v("Registreeri"),_c(VIcon,{staticClass:"ma-2"},[_vm._v("mdi-account-details")])],1),_c(VBtn,{staticClass:"ma-1",attrs:{"to":{name:'login'}}},[_vm._v("Logi sisse"),_c(VIcon,{staticClass:"ma-2"},[_vm._v("mdi-account-arrow-right")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }