<template>
  <v-col class="col-xs-12 col-sm-6 col-md-4 text-xs-center display-1 mx-3">
    <v-card light>
      <v-card-text class="subheading">
        <v-row class="mx-2">
          <p class="subtitle-1">{{ title }}</p>
          <v-spacer class="ml-auto" />
          <v-btn text color="green" :to="{ name: 'about' }">
            <p class="title pt-4"> Transcribe </p>
          </v-btn>
        </v-row>
        <v-form v-model="valid">
          <v-text-field v-if="!islogin" v-model="user.key" label="Võti" prepend-icon="mdi-key-variant" :autofocus="!islogin" :rules="[required('võti')]" />
          <v-text-field v-model="user.email" label="Email" prepend-icon="mdi-email" :autofocus="islogin" :rules="[required('e-mail'), emailFormat()]" />
          <v-text-field v-model="user.password" label="Parool" prepend-icon="mdi-lock" counter
            :type="showPassword ? 'text' : 'password'" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword" :rules="[required('parool'), minLength('Parool', 6)]"
            @keyup.enter="!valid || submitform(user)"
          />
          <v-row class="align-center ml-2 mt-2">
            <v-btn color="primary" @submit.prevent @click="submitform(user)" :disabled="!valid">
              {{ title }}
            </v-btn>
            <v-btn light class="ma-3" :to="changeModule" small>{{ islogin ? 'Registreeri' : 'Logi sisse' }}</v-btn>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import validations from '@/utils/validations'

export default {
  props: {
    submitform: Function,
    islogin: Boolean,
  },
  data() {
    return {
      valid: false,
      showPassword: false,
      user: {
        key: '',
        email: '',
        password: '',
      },
      ...validations,
    }
  },
  computed: {
    title() {
      return this.islogin ? 'Logi sisse' : 'Registreeri'
    },
    changeModule() {
      return this.islogin ? { name: 'register' } : { name: 'login' }
    },
  },
  methods: {
    routeAbout() {
      this.$router.push({name: 'about'})
    }
  }
}
</script>
