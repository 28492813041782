import Vue from 'vue'
import router from './router'
import store from './store'
import axios from 'axios'
import '@mdi/font/css/materialdesignicons.css'

import App from './App.vue'
import vuetify from './plugins/vuetify'

Vue.use(router)

axios.interceptors.response.use(
    undefined,
    err => {
        return new Promise(function (resolve, reject) {
            const { config, response: { status } } = err
            if (status === 401 && config && !config.__isRetryRequest) {
                store.dispatch('users/logoutUser')
                router.push({ name: 'login' })
                throw err
            }
        })
    }
)

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')
