<template>
  <v-row justify="center">
    <UserInfo :submitform="login" islogin />
  </v-row>
</template>

<script>
import UserInfo from '@/components/UserInfo'

export default {
  name: 'Login',
  components: { UserInfo },
  methods: {
    async login(userInfo) {
      let response = await this.$store
          .dispatch('users/loginUser',{
            email: userInfo.email,
            password: userInfo.password
        })
        .then(response => {
          if (response.status !== 200) {
            this.$store.dispatch('message/setMessage', {
              text: response.message,
              type: 'warning',
              timeout: 3000
            })
          } else {
            this.$store.dispatch('message/setMessage', {
              text: response.message,
              type: 'success'
            })
            this.$router.push({ name: 'home' })
          }
        })
        .catch(err => {
          this.$store.dispatch('message/setMessage', {
            text: err.message,
            type: 'error',
            timeout: 5000
          })
        })
    },
  },
}
</script>
