import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"doc-card d-flex flex-column",attrs:{"width":"400","raised":"","id":_vm.doc._id,"color":_vm.resolveColor(_vm.doc.book.tuup)}},[_c(VCardText,{attrs:{"id":"test-container"}},[_c('div',{on:{"click":_vm.openDoc}},[_c('div',{staticClass:"mt-1"},[_c('p',[_c('strong',[_vm._v(_vm._s(`${_vm.doc.book.nimi}: nr ${_vm.doc.number} (${_vm.dateToLocaleEST(_vm.doc.date)})`))])]),_c('p',{staticClass:"subtitle-1"},[_vm._v(" "+_vm._s(`Juhataja: ${_vm.doc.leader.nimi} ${_vm.doc.leader.perenimi}`)+" "),_c('br'),_vm._v(" "+_vm._s(`Protokollija: ${_vm.doc.writer.nimi} ${_vm.doc.writer.perenimi}`)+" ")]),(_vm.doc.attendees != '')?_c('p',{staticClass:"subtitle-2"},[_c(VIcon,{staticClass:"mb-1",attrs:{"small":"","color":"grey"}},[_vm._v("mdi-account-group")]),_vm._v(" "),_c('strong',[_vm._v("Osalejaid:")]),_vm._v(" "+_vm._s(_vm.mapAttendees(_vm.doc.attendees))+" ")],1):_vm._e()]),_c('div',[(_vm.doc.agenda)?_c('div',[_c('strong',{staticStyle:{"color":"black"}},[_vm._v("Päevakord: ")]),_c('vue-markdown',{staticStyle:{"color":"black"},attrs:{"source":_vm.doc.agenda}})],1):_vm._e(),_c(VDivider,{staticClass:"my-2"}),(_vm.doc.text)?_c('div',[_c('vue-markdown',{staticStyle:{"color":"black"},attrs:{"source":this.substrText}})],1):_vm._e()],1)])]),_c(VSpacer),(_vm.doc.keywords)?_c('div',{staticClass:"d-flex flex-wrap pa-3"},_vm._l((_vm.doc.keywords),function(kw,i){return _c(VChip,{key:i,staticClass:"mr-1 mt-1",attrs:{"small":""},on:{"click":_vm.toSearch}},[_vm._v(_vm._s(kw))])}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }