<template>
  <v-row justify="center">
    <v-col class="col-xs-12 col-md-6 text-xs-center display-1 mx-3">
      <v-card light>
        <v-card-title class="headline justify-center text-uppercase">
          About Transcribe 
          <a href="https://github.com/jg-karl">
            <img class="mx-4 mt-2" src="../assets/jgk.png" target="_blank" id="logo" height="30px" />
          </a>
        </v-card-title>
        <v-card-title class="subheading justify-center">
          VueJS app with Vuex, Vuetify, Express and MongoDB
        </v-card-title>
        <v-card-text class="justify-center">
          VueJS application with NodeJS and MongoDb for transcribing protocols books to preserve them in database format.
          Initiative is taken for a better usability of this information. Futher information in Estonian locale.
        </v-card-text>
        <v-card-actions>
          <v-row class="justify-center">
            <v-btn class="ma-1" :to="{name:'register'}">Registreeri<v-icon class="ma-2">mdi-account-details</v-icon></v-btn>
            <v-btn class="ma-1" :to="{name:'login'}">Logi sisse<v-icon class="ma-2">mdi-account-arrow-right</v-icon></v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'About',
}
</script>
