<template>
  <div class="read">
    <v-row class="mx-2 justify-center">
      <v-col cols="12" sm="6" lg="3" class="px-3 align-self-center">
        <v-text-field label="Otsing" v-model="query"
          :background-color="query.length > 0 ? '#ededed' : ''"
          :append-icon="query.length > 0 ? 'mdi-close' : ''" 
          @click:append="query = ''" persistent-hint :hint="`Leitud ${filteredDocs.length} protokolli`"
        ></v-text-field>
        <!-- Separate Search and Filter to separate component with vuex, YT:TM 45m-->
      </v-col>
      <v-col cols="12" sm="6" lg="3" class="px-3">
        <v-menu ref="menu" v-model="dateMenu" :close-on-content-click="false"
          :return-value.sync="dates" transition="scale-transition" 
          offset-y min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field v-model="dateRangeText" label="Kuupäev (vahemik)" prepend-icon="mdi-calendar"
              readonly :background-color="dateRangeText !== 'Asutamisest tänaseni' ? '#ededed' : ''"
              :append-icon="dateRangeText !== 'Asutamisest tänaseni' ? 'mdi-close' : ''" 
              @click:append="resetDates()" v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates" range no-title locale="EST" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateMenu = false">Katkesta</v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(dates)">OK</v-btn>
          </v-date-picker>
        </v-menu>
        <v-chip-group show-arrows v-model="activeFilter" active-class="primary--text" class="mt-n5 mr-4" >
          <v-chip small v-for="(filter, i) in prefilters" :key="i" :value="i" @click="createDateFilter(filter)">{{filter}}</v-chip>
        </v-chip-group>
      </v-col>
      <v-col cols="12" sm="5" lg="3" class="px-1">
        <v-select label="Protokolli tüüp" v-model="bookTypes" :items="Array.from(docsBookTypes)"
                  small-chips deletable-chips multiple :menu-props="{ bottom: true, offsetY: true }" >

          <template v-slot:selection="{ attrs, item, select, selected }">
            <v-chip v-bind="attrs" :input-value="selected" small close @click="selectOnly(item)" @click:close="removeChip(item)" >{{ item }}</v-chip>
          </template>

          <template v-slot:prepend-item>
            <v-list-item ripple @mousedown.prevent @click="toggleSelectAll">
              <v-list-item-action>
                <v-icon :color="bookTypes.length > 0 ? 'indigo darken-4' : ''"> {{ icon }} </v-icon>
              </v-list-item-action>
              <v-list-item-content> <v-list-item-title> Vali kõik </v-list-item-title> </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>

        </v-select>
        <v-autocomplete label="Raamat" cols="12" md="6" dense v-model="bookModel" :items="allOpenBooks" clearable auto-select-first />
      </v-col>
    </v-row>
    <v-row class="align-center">
      <v-col class="d-flex justify-space-between" v-if="$vuetify.breakpoint.smAndUp">
        <v-spacer></v-spacer>
        <v-slider class="mt-6" v-model="pageSize" min="10" max="50" label="Lehel" :thumb-size="24" thumb-label="always"/>
      </v-col>
      <v-col class="justify-space-between" v-if="$vuetify.breakpoint.smAndUp">
        <v-pagination v-model="page" :length="pageCount" :total-visible="7" />
      </v-col>
      <v-col class="d-flex justify-space-between" v-if="$vuetify.breakpoint.smAndUp">
        <v-slider class="mt-6" v-model="summaryLength" min="100" max="900" inverse-label label="Pikkus (tm)" :thumb-size="24" thumb-label="always"/>
        <v-spacer></v-spacer>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-space-between" v-if="$vuetify.breakpoint.xsOnly">
      <v-slider class="mx-6" v-model="pageSize" min="10" max="50" label="Lehel" :thumb-size="24" thumb-label="always"/>
      <v-slider class="mx-6" v-model="summaryLength" min="100" max="900" inverse-label label="Pikkus (tm)" :thumb-size="24" thumb-label="always"/>
      <v-pagination class="mb-3" style="flex:auto;" v-model="page" :length="pageCount" :total-visible="7" />
    </v-row>
    <v-row dense class="px-2 justify-center" v-if="loading == false && allDocs != undefined">
      <SmallDoc
        class="mx-1"
        v-for="doc in paginatedDocs(filteredDocs)"
        :key="doc._id" :doc="doc" :length="summaryLength"
        @opendoc="openDoc(doc)" @tosearch="toSearch"
      />
    </v-row>
    <v-dialog v-model="fullDocView" max-width="1000" scrollable>
      <FullDoc :doc="selectedDoc" :user="user" @closedoc="closeDoc" @tosearch="toSearch" />
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import FullDoc from '@/components/FullDoc'
import SmallDoc from '@/components/SmallDoc'

export default {
  name: 'Collection',
  components: { SmallDoc, FullDoc },
  data: () => ({
    query: '',
    dates: [],
    dateMenu: false,
    page: 1,
    pageSize: 20,
    bookTypes: [],
    bookModel: null,
    prefilters: [
      'Okupatsiooni eelne',
      'Taasiseseisvunud Eestis',
      'Diasporaa välismaal'
    ],
    activeFilter: null,
    summaryLength: 200,
    selectedDoc: null,
    fullDocView: false,
  }),
  mounted() {
    this.$store.dispatch('docs/setLoading', true)
    this.$store.dispatch('data/getAllMembers')
    this.$store.dispatch('data/getAllBooks')
    this.$store.dispatch('data/getAllKeywords')
    setTimeout(() => {
      this.$store.dispatch('docs/getMappedDocs')
    }, 3000)
    this.resetDates()
    this.bookTypes = this.docsBookTypes
    this.createDateFilter('Asutamisest tänaseni')
  },
  computed: {
    ...mapGetters({
      user: 'users/getUser',
      allDocs: 'docs/allDocs',
      loading: 'docs/isLoading',
      getDocById: 'docs/oneDocById',
      docsBookTypes: 'docs/docsBookTypes',
      allDocsDates: 'docs/allDocsDates',
      allBooks: 'data/allBooks',
    }),
    hasSelectAll () {
      return this.bookTypes.length === this.docsBookTypes.length
    },
    hasSomeValues () {
      return this.bookTypes.length > 0 && !this.hasSelectAll
    },
    icon () {
      if (this.hasSelectAll) return 'mdi-close-box'
      if (this.hasSomeValues) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    },
    todayDate() {
      let today = new Date();
      let tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1)
      return tomorrow.toISOString().substr(0, 10)
    },
    dateRangeText() {
      if ( this.dates[0] === '1913-11-10' && this.dates[1] === this.todayDate ) {
        return `Asutamisest tänaseni`
      } else if ( this.dates[0] === '1988-12-01' && this.dates[1] ===  this.todayDate ) {
        return 'Taasiseseisvunud Eestis'
      } else if ( this.dates[0] === '1913-11-10' && this.dates[1] === '1940-09-01' ) {
        return 'Okupatsiooni eelne'
      } else if ( this.dates[0] === '1940-09-01' && this.dates[1] === '1988-12-01' ) {
        return 'Diasporaa välismaal'
      } else if ( this.dates[0] === this.dates[1] ) {
        this.activeFilter = false
        return this.dates[0]
      }
      this.activeFilter = false
      return this.dates.join(' kuni ')
    },
    allOpenBooks() {
      return this.allBooks.filter(book => book.open === true)
    },
    filteredDocs() {
      let docs = this.allDocs
      .filter(doc => this.booktypeCheck(doc))
      .filter(doc => this.datesCheck(doc));
      if (this.bookModel != null) {
        docs = docs.filter(doc => doc.book._id === this.bookModel)
      }
      return this.customFilter(docs);
    },
    pageCount() {
      let div = this.filteredDocs.length / this.pageSize
      let mod = this.filteredDocs.length % this.pageSize
      let res = mod == 0 ? div : Math.floor(div) + 1
      if (res > 1) {
        this.page = 1
      }
      return res
    },
  },
  methods: {
    // bookTypes chips
    removeChip(item) {
      this.bookTypes.splice(this.bookTypes.indexOf(item), 1)
      this.bookTypes = [...this.bookTypes]
    },
    // bookTypes chips
    selectOnly(item) {
      this.bookTypes = [item]
    },
    // bookTypes selectAll
    toggleSelectAll() {
      this.$nextTick(() => {
        if (this.hasSelectAll) {
          this.bookTypes = []
        } else {
          this.bookTypes = this.docsBookTypes;
        }
      })
    },
    paginatedDocs(input) {
      if (this.page === 1) {
        return input.slice(0, this.pageSize)
      } else {
        return input.slice( (this.page - 1) * this.pageSize, this.page * this.pageSize )
      }
    },
    booktypeCheck(doc) {
      return this.bookTypes.includes(doc.book.tuup)
    },
    datesCheck(doc) {
      var date = new Date(doc.date)
      var start = new Date(this.dates[0])
      var end = new Date(this.dates[1])
      return ( date > start.setDate(start.getDate() - 1) && date < end.setDate(end.getDate() + 1) )
    },
    customFilter(docsArray) {
      if (this.query === '') {
        return docsArray;
      }
      return docsArray.filter(doc => {

        return Object.values(doc).some(val => {
          if (val != null && val != undefined) {

            if (Object.prototype.toString.call(val) === '[object String]') {
              return String(val).toLowerCase().includes(this.query.toLowerCase().trim())
            }
            else if (Object.prototype.toString.call(val) === '[object Array]') {
              return Object.values(val).some( el => {
                if (el !== null && el !== undefined) {
                  if (Object.prototype.toString.call(el) === '[object String]') {
                    return String(el).toLowerCase().includes(this.query.toLowerCase().trim()) // comments, keywords
                  }
                  else if (Object.prototype.toString.call(el) === '[object Object]') {
                    return Object.values(el).some(e =>String(e).toLowerCase().includes(this.query.toLowerCase().trim()))
                  }
                }
              })
            }
            else if (Object.prototype.toString.call(val) === '[object Object]') {
              return Object.values(val).some(el => {
                if (el !== null && el !== undefined) {
                  return String(el).toLowerCase().includes(this.query.toLowerCase().trim())
                }
              })
            }
          }
        })
      })
    },
    openDoc(doc) {
      this.selectedDoc = doc
      this.fullDocView = true
    },
    closeDoc() {
      this.fullDocView = false
    },
    toSearch(kw) {
      this.query = kw
    },
    resetDates() {
      this.activeFilter = null
      this.dates = ['1913-11-10', this.todayDate]
    },
    createDateFilter(filter) {
      switch (filter) {
        case 'Asutamisest tänaseni':
          this.dates = ['1913-11-10', this.todayDate]
          break;
        case 'Taasiseseisvunud Eestis':
          this.dates = ['1988-12-01', this.todayDate]
          break;
        case 'Okupatsiooni eelne':
          this.dates = ['1913-11-10','1940-09-01']
          break;
        case 'Diasporaa välismaal':
          this.dates = ['1940-09-01','1988-12-01']
          break;
        default:
          break;
      }
    },
  },
}
</script>
