import axios from 'axios'

export default {
  namespaced: true,
  state: {
    docs: [],
    selectedDoc: {},
    loading: false,
  },
  getters: {
    isLoading: state => state.loading,
    allDocs: state => state.docs,
    oneDocById: state => id => state.docs.find(doc => doc._id === id),
    allDocsDates: state => state.docs.map(doc => doc.date),
    docsBookTypes: state => Array.from(new Set(state.docs.map(doc => doc.book.tuup))),
    getUserEntries: state => currentUser => state.docs.filter( doc => doc.user === currentUser)
  },
  actions: {
    async getMappedDocs({ commit, rootGetters }) {
      commit('SET_LOADING', true)
      const res = await axios({
        method: 'get',
        url: 'api/docs'
      }).then(response => response.data.map(doc => ({
          ...doc,
          date: new Date(doc.date).toISOString().substr(0, 10),
          createdAt: new Date(doc.createdAt).toISOString(),
          book: rootGetters['data/oneBookById'](doc.book),
          leader: rootGetters['data/oneMemberById'](doc.leader),
          writer: rootGetters['data/oneMemberById'](doc.writer),
          attendees: doc.attendees.map(el => rootGetters['data/oneMemberById'](el))
        }))
      )
      commit('SET_DOCS', res)
    },
    async addNewDoc({ commit, dispatch }, doc) {
      commit('SET_LOADING', true)
      doc.date = new Date(doc.date).toISOString().substr(0, 10)
      doc.createdAt = Date.now()
      const res = await axios({
        method: 'post',
        url: 'api/docs',
        data: doc
      }) // set message with res
      dispatch('docs/getMappedDocs', null, { root: true })
    },
    async updateOneDoc({ commit, dispatch }, updatedDoc) {
      commit('SET_LOADING', true)
      updatedDoc.updatedAt = Date.now()
      const res = await axios({
        method: 'put',
        url: `api/docs/${updatedDoc._id}`,
        data: updatedDoc
      }) // set message with res
      dispatch('docs/getMappedDocs', null, { root: true })
    },
    async deleteOneDoc({ commit }, id) {
      commit('SET_LOADING', true)
      const res = await axios({
        method: 'delete',
        url: `api/docs/${id}`
      })
      // set message with res
      commit('DELETE_DOC', id)
    },
    emptyStore({ commit }) {
      commit('EMPTY_STORE')
    },
    setLoading({ commit }, bool) {
      commit('SET_LOADING', bool)
    },
  },
  mutations: {
    SET_LOADING: (state, bool) => (state.loading = bool),
    SET_DOCS: (state, docs) => {
      state.docs = docs
      state.loading = false
    },
    DELETE_DOC: (state, id) => {
      state.docs = state.docs.filter(doc => doc._id !== id)
      state.loading = false
    },
    EMPTY_STORE: state => {
      state.docs = []
      state.selectedDoc = {}
    },
  },
}
