<template>
  <div>
    <Dashboard v-if="loading == false" class="justify-center"></Dashboard>
    <EventCalendar v-if="loading == false" class="justify-center" ></EventCalendar>
  </div>
</template>

<script>
import Dashboard from '@/components/Dashboard'
import EventCalendar from '@/components/EventCalendar'
import { mapGetters } from 'vuex'

export default {
  name: 'home',
  components: { Dashboard, EventCalendar },
  created() {
    this.$store.dispatch('docs/setLoading', true)
    this.$store.dispatch('data/getAllMembers')
    this.$store.dispatch('data/getAllBooks')
    this.$store.dispatch('data/getAllConvents')
    this.$store.dispatch('data/getAllKeywords')
    setTimeout(() => {
      this.$store.dispatch('docs/getMappedDocs')
      this.$store.dispatch('records/getAllRecords')
    }, 500)
  },
  mounted() {},
  computed: {
    ...mapGetters({
      loading: 'docs/isLoading'
    })
  },
  methods: {}
}
</script>
