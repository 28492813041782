<template>
  <v-card raised class="ma-1">
    <v-row>
      <v-col cols=10>
        <v-card-title>{{ record.main }}</v-card-title>
        <v-card-text>{{ `${record.convent.abbr} (${dateToLocaleEST(record.date)}) @${record.location}` }}</v-card-text>
      </v-col>
      <v-col>
        <v-card-actions>
          <v-spacer class="mx-auto"/>
          <v-btn outlined class="mr-3" text @dblclick="deleteRecord">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: 'SmallRecord',
  props: { record: Object },
  methods: {
    openRecord() {
      this.$emit('openrecord', this.record._id)
    },
    deleteRecord(id) {
      this.$emit('deleterecord', this.record._id)
    },
    dateToLocaleEST(dateString) {
      return new Date(dateString).toLocaleDateString('ET-ee', {
        year: 'numeric', month: 'short', day: 'numeric',
      })
    },
  },
}
</script>
