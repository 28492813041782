import Vuex from 'vuex'
import Vue from 'vue'
import users from './modules/users'
import docs from './modules/docs'
import records from './modules/records'
import data from './modules/data'
import message from './modules/message'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		users,
		docs,
		records,
		data,
		message
	},
})
