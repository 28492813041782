import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'

import Home from '@/pages/Home'
import About from '@/pages/About'
import Login from '@/pages/Login'
import Register from '@/pages/Register'

import Editor from '@/pages/Editor'
import Records from '@/pages/Records'
import Collection from '@/pages/Collection'

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/about",
      name: "about",
      component: About
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/register",
      name: "register",
      component: Register
    },
    {
      path: '/collection',
      name: 'collection',
      component: Collection,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/editor/:id',
      name: 'editor',
      component: Editor,
      props: true,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: '/records',
      name: 'records',
      component: Records,
      meta: {
        requiresAuth: true
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (store.getters['users/isLoggedIn']) {
      next()
      return
    }
    next('/login')
  } else {
    next();
  }
});

export default router;
