import axios from 'axios'

export default {
  namespaced: true,
  state: {
    members: [],
    keywords: [],
    books: [],
    convents: [],
    links: []
  },
  getters: {
    allMembers: state => state.members,
    allKeywords: state => state.keywords,
    allBooks: state => state.books,
    allConvents: state => state.convents,
    allLinks: state => state.links,

    oneMemberById: state => id => {
      return state.members.find(member => member._id === id)
    },
    oneBookById: state => id => {
      return state.books.find(book => book._id === id)
    },
    oneConventById: state => id => {
      return state.convents.find(convent => convent._id === id)
    },
    oneLinkByBookId: state => id => {
      return state.links.find(link => link.bookid === id)
    },
    isKeyword: state => query => {
      return state.keywords.some(kw => kw.text === query)
    }
  },
  actions: {
    // KEYWORDS
    async getAllKeywords({ commit }) {
      const res = await axios({
        method: 'get',
        url: 'api/data/keywords'
      })
      commit('SET_KEYWORDS', res.data)
    },
    async addNewKeyword({ commit }, kw) {
      await axios({
        method: 'post',
        url: 'api/data/keywords',
        data: { text: kw }
      })
      commit('NEW_KEYWORD', kw)
    },
    // MEMBERS
    async getAllMembers({ commit }) {
      const res = await axios({
        method: 'get',
        url: 'api/data/members'
      })
      commit(
        'SET_MEMBERS',
        res.data.map(member => ({
          ...member,
          text: `${member.nimi} ${member.perenimi} (${member.reb.substring(0, 4)})`,
          value: member._id
        }))
      )
    },
    async addNewMember({ commit }, member) {
      const res = await axios({
        method: 'post',
        url: 'api/data/members',
        data: member
      })
      commit('NEW_MEMBER', res.data)
    },
    // BOOKS
    async getAllBooks({ commit }) {
      const res = await axios({
        method: 'get',
        url: 'api/data/books'
      })
      commit(
        'SET_BOOKS',
        res.data.map(book => ({
          ...book,
          text:
            `${book.num}: ${book.konvent} ${book.tuup}` +
            ` (${book.algus.substring(0, 4)}-${book.lopp.length > 0 ? book.lopp.substring(0, 4) : '...'})`,
          value: book._id
        }))
      )
    },
    // CONVENTS
    async getAllConvents({ commit }) {
      const res = await axios({
        method: 'get',
        url: 'api/data/convents'
      })
      commit(
        'SET_CONVENTS',
        res.data.map(convent => ({
          ...convent,
          text: `${convent.abbr}: ${convent.name}`,
          value: convent._id
        }))
      )
    },
    // LINKS
    async getAllLinks({ commit }) {
      const res = await axios({
        method: 'get',
        url: 'api/data/links'
      })
      commit(
        'SET_LINKS',
        res.data.map(link => ({
          ...link,
          text: `${link.code}: ${link.info}`,
          value: link._id
        }))
      )
    },
    emptyStore({ commit }) {
      commit('EMPTY_STORE')
    },
  },
  mutations: {
    SET_KEYWORDS: (state, keywords) => (state.keywords = keywords),
    SET_MEMBERS: (state, members) => (state.members = members),
    SET_BOOKS: (state, books) => (state.books = books),
    SET_CONVENTS: (state, convents) => (state.convents = convents),
    SET_LINKS: (state, links) => (state.links = links),
    
    NEW_KEYWORD: (state, kw) => {
      return state.keywords.push({text: kw})
    },
    NEW_MEMBER: (state, member) => state.members.push(member),

    EMPTY_STORE: state => {
      state.keywords = []
      state.members = []
      state.books = []
      state.convents = []
      state.links = []
    },
  },
}
