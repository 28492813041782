<template>
  <v-row justify="center">
    <UserInfo :submitform="register" />
  </v-row>
</template>

<script>
import UserInfo from '@/components/UserInfo'

export default {
  name: 'Registreeri',
  components: { UserInfo },
  methods: {
    async register(userInfo) {
      let response = await this.$store.dispatch('users/registerUser', userInfo)
        .then(response => {
          if (response.status != 200) {
            this.$store.dispatch('message/setMessage', {
              text: response.message,
              type: 'warning',
              timeout: 3000
            })
          } else {
            this.$store.dispatch('message/setMessage', {
              text: response.message,
              type: 'success'
            })
            this.$router.push({ name: 'login' })
          }
        })
        .catch(err => {
          this.$store.dispatch('message/setMessage', {
            text: response.message,
            type: 'error',
            timeout: 5000
          })
        })
    },
  },
}
</script>
