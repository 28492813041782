<template>
  <v-app-bar app short absolute width="100%">
    
    <v-btn text @click="routeAbout">
      <v-toolbar-title class="headline text-uppercase"> Transcribe </v-toolbar-title>
    </v-btn>
    <v-switch class="ml-4 mt-5" v-model="$vuetify.theme.dark"/>
    <div class="flex-grow-1"></div>
    
    <div class="app-bar">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-chip v-on="on"  v-show="$vuetify.breakpoint.smAndUp" class="mx-2" rounded>
            <v-avatar left> <v-icon>mdi-account-circle</v-icon> </v-avatar> {{ user }}
          </v-chip>
        </template>
        <span>Kasutaja: {{ user }} <br> Protokolle sisestanud: {{ getUserEntries(user).length }}</span>
      </v-tooltip>

      <v-tooltip bottom color="green">
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon color="green" @click="refreshDatabase" v-if="$vuetify.breakpoint.smAndUp"><v-icon>mdi-refresh</v-icon></v-btn>
        </template>
        <span>Lae andmed uuesti andmebaasist</span>
      </v-tooltip>

      <v-divider class="mr-3" vertical inset></v-divider>

      <v-btn-toggle v-model="selectedPage" v-if="!$vuetify.breakpoint.xsOnly" dark dense active-class>
        <v-btn value="1" text outlined :to="{ name: 'collection' }" >
          {{ $vuetify.breakpoint.smAndDown ? 'PT' : 'Protokollid' }}
        </v-btn>
        <v-btn value="2" text outlined :to="{ name: 'editor', params: { id: 'new' } }" >
          {{ $vuetify.breakpoint.smAndDown ? 'LS' : 'Lisa'}}
        </v-btn>
        <v-btn value="3" text outlined :to="{ name: 'records' }" >
          {{ $vuetify.breakpoint.smAndDown ? ' KR' : 'Kroonika' }}
        </v-btn>
      </v-btn-toggle>

      <v-divider class="mr-3" vertical inset></v-divider>

      <v-btn text :icon="$vuetify.breakpoint.smAndDown" @click="logout">
        {{ $vuetify.breakpoint.smAndDown ? '' : `Logi välja` }}<v-icon class="ml-1 mr-3">mdi-logout</v-icon>
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    selectedPage: null,
  }),
  computed: {
    ...mapGetters({
      user: 'users/getUser',
      getUserEntries: 'docs/getUserEntries'
    })
  },
  created() {
    // FIXME: hack for dev env hot reload
    this.$store.dispatch("users/refreshToken");
  },
  methods: {
    logout() {
      this.$store.dispatch('users/logoutUser')
      this.$router.push({ name: 'login' })
    },
    routeAbout() {
      this.$router.push({name: 'home'})
    },
    refreshDatabase() {
      this.$store.dispatch('docs/setLoading', true)
      this.$store.dispatch('data/getAllMembers')
      this.$store.dispatch('data/getAllBooks')
      this.$store.dispatch('data/getAllConvents')
      this.$store.dispatch('data/getAllKeywords')
      setTimeout(() => {
        this.$store.dispatch('docs/getMappedDocs')
        this.$store.dispatch('records/getAllRecords')
      }, 2000)
    }
  },
}
</script>

<style scoped>
.app-bar {
  white-space: nowrap;
}
</style>
