<template>
  <v-card class="doc-card d-flex flex-column" width="400" raised :id="doc._id" :color="resolveColor(doc.book.tuup)" >
    <v-card-text id="test-container">
      <div  @click="openDoc" class="">
        <div class="mt-1">
          <p>
            <strong>{{ `${doc.book.nimi}: nr ${doc.number} (${dateToLocaleEST(doc.date)})` }}</strong>
          </p>
          <p class="subtitle-1">
            {{ `Juhataja: ${doc.leader.nimi} ${doc.leader.perenimi}` }} <br />
            {{ `Protokollija: ${doc.writer.nimi} ${doc.writer.perenimi}` }}
          </p>
          <p class="subtitle-2" v-if="doc.attendees != ''">
            <v-icon small class="mb-1" color="grey">mdi-account-group</v-icon> <strong>Osalejaid:</strong>
            {{ mapAttendees(doc.attendees) }}
          </p>
        </div>
        <div>
          <div v-if="doc.agenda">
            <strong style="color: black">Päevakord: </strong>
            <vue-markdown :source="doc.agenda" style="color: black"/>
          </div>
          <v-divider class="my-2"></v-divider>
          <div v-if="doc.text">
            <vue-markdown :source="this.substrText" style="color: black"/>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-spacer></v-spacer>
    <div v-if="doc.keywords" class="d-flex flex-wrap pa-3">
      <v-chip class="mr-1 mt-1" small v-for="(kw, i) in doc.keywords" :key="i" @click="toSearch">{{ kw }}</v-chip>
    </div>
  </v-card>
</template>

<script>
import VueMarkdown from '@adapttive/vue-markdown';

export default {
  name: 'SmallDoc',
  props: { doc: Object, length: Number },
  components: { VueMarkdown },
  computed: {
    substrText() {
      return this.doc.text.substr(0, this.length) + (this.doc.text.length > this.length ? '...' : '')
    }
  },
  methods: {
    mapAttendees(attendees) {
      let mapped = attendees.map(el => `${el.nimi} ${el.perenimi}`)
      return mapped.join(', ')
    },
    openDoc() {
      this.$emit('opendoc', this.doc._id)
    },
    toSearch(event) {
      this.$emit('tosearch', event.target.innerHTML)
    },
    dateToLocaleEST(dateString) {
      return new Date(dateString).toLocaleDateString('ET-ee', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })
    },
    resolveColor(type) {
      switch (type) {
        case 'KKK':
          return '#baffc0'
        case 'EKK':
          return '#80d9ff'
        case 'PRAES':
          return '#ffdb80'
        case 'Vigilia':
        case 'Kaljola':
        case 'tundamtu':
          return '#ff4fc0'
        default:
          return '#bfafc0'
      }
    },
  },
}
</script>

<style scoped>
.doc-card {
  margin-bottom: 5px;
  box-shadow: 3px 3px 10px gray;
}
.doc-card p {
  color: #000;
  font-size: 12pt;
}
</style>
