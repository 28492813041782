<template>
    <v-app>
        <AppBar v-if="isLoggedIn" width="100%" />

        <v-progress-linear v-show="loading" absolute :indeterminate="loading" height="4"></v-progress-linear>

        <v-main class="ma-2">
            <router-view />
        </v-main>
        
        <MainMessageSnackbar :messages="messages" />
    </v-app>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters } from 'vuex'

import AppBar from '@/components/AppBar'
import MainMessageSnackbar from '@/components/MainMessageSnackbar'

export default {
    name: 'App',
    components: { AppBar, MainMessageSnackbar },
    computed: {
        ...mapState({
            user: state => state.users.user,
            messages: state => state.message.messages,
            loading: state => state.docs.loading,
        }),
        ...mapGetters({
            isLoggedIn: 'users/isLoggedIn',
        }),
    },
}
</script>
