<template>
  <v-container justify="center">
    <p>
      Saad lisada uusi protokolle. Kõigepealt vali endale kirjutamiseks protokolliraamat ja siis leiad
      <a :href="selectedBookLink" target="_blank">digipildid siit.</a>
    </p>
    <v-form ref="doc" v-model="valid">
      <v-row>
        <v-autocomplete label="Raamat" cols="12" md="6" class="px-n2" dense outlined
                        v-model="doc.book" :items="allOpenBooks" :rules="genRules" :filter="customFilter"
        />
        <v-text-field cols="12" md="6" dense outlined :rules="genRules" label="Number" type="number" v-model="doc.number"/>
        <v-menu ref="menu" v-model="dateMenu" close-on-content-click offset-x offset-y min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field label="Kuupäev" cols="12" md="6" dense outlined append-icon="mdi-calendar"
                          v-on="on" v-model="doc.date" :rules="genRules"
            />
          </template>
          <v-date-picker v-model="doc.date" show-current type="date" :events="allDocsDates" no-title scrollable locale="EST">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateMenu = false">Katkesta</v-btn>
            <v-btn color="primary" @click="$refs.menu.save(doc.date)">OK</v-btn>
          </v-date-picker>
        </v-menu>
      </v-row>
      <v-row>
        <v-autocomplete
            cols="12" md="6" dense outlined label="Juhataja" auto-select-first
            v-model="doc.leader" :rules="genRules" :items="allMembers" :filter="memberFilter"
        />
        <v-autocomplete
            cols="12" md="6" dense outlined label="Protokollija" auto-select-first
            v-model="doc.writer" :rules="genRules" :items="allMembers" :filter="memberFilter"
        />
        <v-text-field
            cols="12" md="6" dense outlined label="Toimumiskoht"
            v-model="doc.location" append-icon="mdi-map-marker"
        />
      </v-row>
      <v-row>
        <v-autocomplete
            outlined small-chips deletable-chips multiple auto-select-first hide-selected label="Osalejad" item-value="_id"
            v-model="doc.attendees" :rules="genRules" :items="allMembers" :filter="memberFilter"
            :search-input.sync="query" @change="query = ''"
        />
      </v-row>
      <v-row>
        <v-textarea cols="12" dense rows="2" filled auto-grow label="Sissejuhatavad märkused" v-model="doc.preText"></v-textarea>
        <v-textarea cols="12" dense rows="2" filled auto-grow label="Päevakord" v-model="doc.agenda"></v-textarea>
      </v-row>
      <v-row>
        <v-textarea dense rows="4" filled auto-grow label="Protokolli sisutekst" v-model="doc.text" :rules="genRules"></v-textarea>
      </v-row>
      <v-row>
        <v-textarea dense rows="2" filled auto-grow label="Lõpetavad märkused" v-model="doc.afterText"></v-textarea>
      </v-row>
      <br/><hr class="mx-n6"/><br/>
      <v-row dense class="d-flex align-center justify-space-between">
        <div>Lisainfo sisestajalt:</div>
        <div v-if="user === 'arhivaar'" class="mx-4">
          <v-checkbox v-model="doc.locked" label="Kontrollitud/lukustatud"></v-checkbox>
        </div>
      </v-row>
      <v-row>
        <v-autocomplete
            dense outlined small-chips deletable-chips multiple auto-grow append-icon="mdi-message-outline" hide-no-data
            hide-selected label="Kommentaarid" v-model="doc.comments" :items="doc.comments" :search-input.sync="commentInput" @keyup.enter="addComment"
        />
        <v-autocomplete
            dense outlined small-chips deletable-chips multiple auto-select-first hide-selected v-model="doc.keywords" label="Märksõnad"
            :no-data-text="keywordMenuInfo" :items="allKeywords" :filter="customFilter" :search-input.sync="kwQuery" @change="kwQuery = ''" @keyup.native.alt.65="addKeyword"
        >
        </v-autocomplete>
      </v-row>
      <v-row>
        <v-btn class="ma-1" :disabled="!valid" color="success" v-on:click="saveDoc()">
          Salvesta
          <v-icon>mdi-update</v-icon>
        </v-btn>
        <v-btn class="ma-1" outlined color="error" v-on:click="resetDoc()">Tühjenda
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-switch class="ml-5 mt-1" v-model="continueEntry" :label="`TODO`" style="display:none;"></v-switch>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn class="ma-2" icon small color="blue-grey" dark v-on="on" target="_blank" href="https://miaolz123.github.io/vue-markdown/"><v-icon>mdi-help</v-icon></v-btn>
          </template>
          Sissejuhatus, päevakord, sisutekst ja lisamärkused toetavad Markdown formaati.<br/>
          Täpsemalt https://miaolz123.github.io/vue-markdown/
        </v-tooltip>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'Editor',
  props: {id: String},
  data: () => ({
    updating: false,
    continueEntry: false,
    valid: false,
    query: '',
    kwQuery: '',
    dateMenu: false,
    commentInput: '',
    booklink: null,
    doc: {
      book: null,
      number: null,
      date: null,
      location: null,
      leader: null,
      writer: null,
      attendees: null,
      preText: null,
      agenda: null,
      text: null,
      afterText: null,
      keywords: [],
      comments: [],
    },
    genRules: [v => !!v || 'Kohustuslik'],
  }),
  created() {
    this.$store.dispatch('data/getAllMembers')
    this.$store.dispatch('data/getAllBooks')
    this.$store.dispatch('data/getAllKeywords')
    this.$store.dispatch('data/getAllLinks')
  },
  mounted() {
    if (this.id != null && this.id.length === 24) {
      this.doc = this.oneDocById(this.id)
      this.doc.attendees = this.doc.attendees.map(el => el.value)
      this.updating = true
    }
  },
  computed: {
    ...mapGetters({
      user: 'users/getUser',
      allMembers: 'data/allMembers',
      allBooks: 'data/allBooks',
      allKeywords: 'data/allKeywords',
      isKeyword: 'data/isKeyword',
      oneDocById: 'docs/oneDocById',
      allDocsDates: 'docs/allDocsDates',
      oneLinkByBookId: 'data/oneLinkByBookId'
    }),
    keywordMenuInfo() {
      if (this.doc.keywords.some(el => el === this.kwQuery)) {
        return `Märksõna "${this.kwQuery}" juba valitud`
      }
      return `Vajuta Alt + A "${this.kwQuery}" märksõna sisestamiseks`
    },
    allOpenBooks() {
      return this.allBooks.filter(book => book.open === true)
    },
    selectedBookLink() {
      // if (this.doc.book == null) {
      //   return `https://www.dropbox.com/`
      // } else {
      //   return `https://www.dropbox.com${this.oneLinkByBookId(this.doc.book).link}`
      // }
      return `https://www.dropbox.com/sh/psjlucegmptkvu5/AADkuRYNqIex1kuFJgShddbWa?dl=0`
    }
  },
  methods: {
    ...mapActions({
      setLoading: 'docs/setLoading',
      addNewDoc: 'docs/addNewDoc',
      addNewKeyword: 'data/addNewKeyword',
      updateOneDoc: 'docs/updateOneDoc',
    }),
    async saveDoc() {
      this.validateDoc()
      if (this.valid) {
        this.doc.user = this.user
        await this.updating === true ? this.updateOneDoc(this.doc) : this.addNewDoc(this.doc)
        this.updating = false
        if (!this.continueEntry) {
          this.$router.push({name: 'collection'})
        }
      }
    },
    validateDoc() {
      this.valid = this.$refs.doc.validate()
    },
    resetDoc() {
      this.$refs.doc.reset()
    },
    memberFilter(item, query) {
      return item.text.toLowerCase().indexOf(query.toLowerCase()) > -1
    },
    customFilter(item, query) {
      return Object.values(item).some(val => {
        if (typeof val == 'string') {
          return String(val).toLowerCase().includes(query.toLowerCase().trim())
        }
      })
    },
    addKeyword() {
      this.$nextTick(() => {
        this.kwQuery = this.kwQuery.trim()
        if (this.kwQuery === '') return;
        if (this.kwQuery !== null) {
          if (!this.isKeyword(this.kwQuery)) {
            this.addNewKeyword(this.kwQuery)
            this.doc.keywords.push(...this.kwQuery.split(';'))
            this.$nextTick(() => {
              this.kwQuery = ''
            })
          }
        }
      })
    },
    addComment() {
      this.$nextTick(() => {
        if (this.commentInput === '') return;
        if (this.commentInput != null) {
          this.commentInput = this.commentInput.trim()
          this.doc.comments.push(...this.commentInput.split(';'))
          this.$nextTick(() => {
            this.commentInput = null
          })
        }
      })
    },
  },
}
</script>

<style scoped>
.v-input {
  padding: 2px;
}
</style>
