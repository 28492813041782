<template>
  <v-card :id="doc._id">
    <v-toolbar flat short :class="[$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4']" elevation="2">
      <v-toolbar-title class="h4">{{
        `${doc.book.text.substr(4)}: nr ${doc.number} (${dateToLocaleEST(doc.date)})`
      }}</v-toolbar-title>
      <v-tooltip right>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" v-if="doc.user && doc.createdAt" class="mx-2">mdi-account-edit</v-icon>
        </template>
        <span>{{ `${doc.user}@${new Date(doc.createdAt).toISOString().substr(0,10)}` }}</span>
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeDoc()"><v-icon>mdi-close</v-icon></v-btn>
    </v-toolbar>
    <v-card-text class="mb-2">
      <div class="px-3 py-2 main-info">
        <p class="subtitle-1">
          <strong>Juhataja: {{ doc.leader.text }} | Protokollija: {{ doc.writer.text }}</strong>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon round v-on="on" v-if="doc.location" small class="mx-2 mb-1">mdi-map-marker</v-icon>
            </template>
            <span>{{ doc.location }}</span>
          </v-tooltip>
        </p>
        <p class="subtitle-2" v-if="doc.attendees != null">
          <v-icon class="mr-2 mb-1" small>mdi-account-group</v-icon>
          <v-chip class="mr-1 mb-1" small outlined v-for="(attendee, i) in doc.attendees" :key="i" @click="toSearch">
            {{ attendee.text }}
          </v-chip>
        </p>
      </div>
      <div class="pa-1 ml-2 main-text mx-3">
        <div v-if="doc.preText" class="mt-n4 mb-2" :class="[$vuetify.theme.dark ? 'white--text' : 'black--text']">
          <vue-markdown :source="doc.preText" :class="[$vuetify.theme.dark ? 'white--text' : 'black--text']"/>
        </div>
        <div v-if="doc.agenda" class="py-1">
          <strong :class="[$vuetify.theme.dark ? 'white--text' : 'black--text']">Päevakord: </strong>
          <vue-markdown :source="doc.agenda" :class="[$vuetify.theme.dark ? 'white--text' : 'black--text']"/>
        </div>
        <br>
        <div v-if="doc.text" :class="[($vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-3'),'px-4 py-3 rounded']">
          <vue-markdown :source="doc.text" :class="[$vuetify.theme.dark ? 'white--text' : 'black--text']"/>
        </div>
        <br>
        <div v-if="doc.afterText" class="subtitle-2 ml-2">
          <vue-markdown :source="doc.afterText" :class="[$vuetify.theme.dark ? 'white--text' : 'black--text']"/>
        </div>
      </div>
      <v-row v-if="doc.keywords" class="ml-3 mt-2">
        <v-chip small class="mx-1" v-for="(e, i) in doc.keywords" :key="i" @click="toSearch">{{ e }}</v-chip>
      </v-row>
    </v-card-text>

    <v-card-actions :class="[$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4']">
      <div v-if="doc.locked">Kontrollitud ja lukustatud ({{ this.dateToLocaleEST(doc.updatedAt) }})</div>
      <v-spacer></v-spacer>
      <v-menu v-model="commentView" offset-y top>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" v-show="commentsAvailable" text color="grey">
            <v-icon>mdi-comment</v-icon>{{ `(${doc.comments.length})` }}
          </v-btn>
        </template>
        <v-card width="300">
          <v-card-text>
            <ul>
              <li v-for="(comment, i) in doc.comments" :key="i">
                {{ comment }}
              </li>
            </ul>
          </v-card-text>
        </v-card>
      </v-menu>
        <v-btn v-if="this.user === 'arhivaar' || doc.locked !== true" small text color="orange darken-3" @click="editDoc()">
          Muuda <v-icon>mdi-account-edit</v-icon>
        </v-btn>
        <v-btn v-if="this.user === 'arhivaar'" small class="pa-n3" text color="red darken-3" @click="confirmView = true">
          Kustuta <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-btn small color="primary" @click="closeDoc()">Sule<v-icon>mdi-close</v-icon> </v-btn>
    </v-card-actions>

    <v-dialog v-model="confirmView" max-width="300px">
      <v-card>
        <v-toolbar flat short :class="[$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-4']" dense>
          <v-toolbar-title>Oled kindel, et kustutan?</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4">
          <p><strong>Kas soovid kindlasti kustutada:</strong></p>
          Raamat: {{ doc.book.text }} <br />
          Kuupäev: {{ doc.date }} <br />
          Juhataja: {{ doc.leader.text }} <br />
          Protokollija: {{ doc.writer.text }}
        </v-card-text>
        <v-card-actions class="pa-4">
          <v-spacer></v-spacer>
          <v-btn color="red darken-3" outlined @click="deleteDoc">Kustuta<v-icon>mdi-delete</v-icon></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex';
import VueMarkdown from '@adapttive/vue-markdown';

export default {
  name: 'Doc',
  props: {
    doc: Object,
    user: String,
  },
  components: { VueMarkdown },
  data: () => ({
    commentView: false,
    confirmView: false,
  }),
  computed: {
    commentsAvailable() {
      return this.doc.comments != null && this.doc.comments.length > 0
    },
  },
  methods: {
    ...mapActions({
      deleteOneDoc: 'docs/deleteOneDoc',
    }),
    deleteDoc() {
      this.deleteOneDoc(this.doc._id)
      this.closeDoc()
    },
    toSearch(event) {
      this.$emit('tosearch', event.target.innerHTML)
      this.closeDoc()
    },
    editDoc() {
      this.$router.push({ name: 'editor', params: { id: this.doc._id } })
    },
    closeDoc() {
      this.$emit('closedoc')
    },
    dateToLocaleEST(dateString) {
      return new Date(dateString).toLocaleDateString('ET-ee', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
    },
  },
}
</script>

<style scoped>
.main-info {
  color: 'grey';
}
.main-text {
  color: black;
  border-radius: 10px;
}
.sisu {
  font-size: 13pt;
  color: black;
}
.sisu p {
  margin-block-end: 5px;
}
.v-toolbar {
  margin-bottom: 12px;
  position: sticky;
  top: 0;
  z-index: 999;
}
.headerRow {
  padding: 2px;
  padding-top: 4px;
  border-bottom: 1px solid black;
}
</style>
