<template>
  <v-container class="kroonika">
    <h1>Kroonika</h1>
    <p>
      Rotalia ajalookroonika: allikatele viidetega ja lisamärkustega. Seotud kalendri, konventide ja muu võimalikuga.
    </p>
    <v-form ref="record" v-model="valid">
      <v-row>
        <v-select cols="12" md="4" dense outlined label="Konvent" v-model="record.convent" :items="allConvents" :rules="genRules" ></v-select>
        <v-menu ref="menu" v-model="dateMenu" close-on-content-click offset-y min-width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field cols="12" md="4" v-on="on" dense outlined :rules="genRules" label="Kuupäev" v-model="record.date" append-icon="mdi-calendar"/>
          </template>
          <v-date-picker v-model="record.date" show-current type="date" :events="allRecordsDates" no-title scrollable locale="EST">
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dateMenu = false">Katkesta</v-btn>
            <v-btn color="primary" @click="$refs.menu.save(record.date)">OK</v-btn>
          </v-date-picker>
        </v-menu>
        <v-text-field cols="12" md="4" dense outlined label="Asukoht" v-model="record.location" :rules="genRules" />
      </v-row>
      <v-row>
        <v-text-field dense outlined label="Kirje" v-model="record.main" :rules="genRules"></v-text-field>
        <v-text-field dense outlined label="Allikas (täpsusta semikooloniga)" v-model="record.origin" :rules="genRules">
          <template slot="append-outer">
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn icon small color="blue-grey" dark v-on="on"><v-icon>mdi-help</v-icon></v-btn>
              </template>
              <span width="100px">
                Viita: pealkiri; teksti autor; (lehekülg)<br />
                Korp Rotalia 90; Andres Salu; Lk 109 <br /> Meie Side 1964 1/2; Johannes Pedak; Lk 34
              </span>
            </v-tooltip>
          </template>
        </v-text-field>
      </v-row>
      <v-row dense class="my-n2">
        <p>Lisainfo sisestajalt:</p>
      </v-row>
      <v-row>
        <v-autocomplete dense outlined small-chips deletable-chips multiple auto-grow append-icon="mdi-message-outline" hide-no-data hide-selected 
          label="Kommentaarid" v-model="record.comments" :items="record.comments" :search-input.sync="commentInput" @keyup.enter="addComment" 
        />
        <v-autocomplete dense outlined small-chips deletable-chips multiple auto-select-first hide-selected v-model="record.keywords" 
          label="Märksõnad" :no-data-text="keywordMenuInfo" :items="allKeywords" :filter="customFilter" :search-input.sync="kwQuery" 
          @change="kwQuery = ''" @keyup.native.alt.65="addKeyword"
        />
      </v-row>
      <v-row class="pb-3">
        <v-btn class="ma-1" :disabled="!valid" color="success" v-on:click="saveRecord()">
          Salvesta<v-icon>mdi-update</v-icon>
        </v-btn>
        <v-btn class="ma-1" outlined color="error" v-on:click="resetRecord()">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </v-row>
    </v-form>
    <v-col v-if="allRecords != undefined" >
      <SmallRecord v-for="rec in allRecords" :key="rec._id" :record="rec" @openrecord="openRecord(rec)" @deleterecord="deleteRecord" />
    </v-col>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SmallRecord from "@/components/SmallRecord"

export default {
  name: 'Kroonika',
  components: { SmallRecord },
  data: () => ({
    valid: false,
    dateMenu: false,
    query: '',
    kwQuery: '',
    commentInput: '',
    record: {
      convent: null,
      location: null,
      date: null,
      origin: null,
      main: null,
      keywords: [],
      comments: [],
    },
    genRules: [v => !!v || 'Kohustuslik'],
  }),
  created() {
    this.$store.dispatch('docs/setLoading', true)
    this.$store.dispatch('data/getAllConvents')
    this.$store.dispatch('data/getAllKeywords')
    setTimeout(() => {
      this.$store.dispatch('records/getAllRecords')
    }, 1000)
  },
  computed: {
    ...mapGetters({
      user: 'users/getUser',
      allRecords: 'records/allRecords',
      oneRecordById: 'records/oneRecordById',
      allRecordsDates: 'records/allRecordsDates',
      allConvents: 'data/allConvents',
      allKeywords: 'data/allKeywords',
      isKeyword: 'data/isKeyword',
    }),
    keywordMenuInfo() {
      if ( this.record.keywords.some(el => el === this.kwQuery) ) {
        return `Märksõna "${this.kwQuery}" juba valitud`
      }
      return `Vajuta Alt + A "${this.kwQuery}" märksõna sisestamiseks`
    }
  },
  methods: {
    ...mapActions({
      addNewRecord: 'records/addNewRecord',
      addNewKeyword: 'data/addNewKeyword',
    }),
    async reloadRecords() {
      await this.$store.dispatch('records/getAllRecords')
    },
    validateRecord() {
      this.valid = this.$refs.record.validate()
    },
    resetRecord() {
      this.$refs.record.reset()
    },
    async saveRecord() {
      this.validateRecord()
      if (this.valid) {
        this.record.user = this.user
        await this.addNewRecord(this.record)
        this.resetRecord()
        this.reloadRecords()
      }
    },
    async deleteRecord(id) {
      await this.$store.dispatch('records/deleteRecord', id)
      this.reloadRecords()
    },
    customFilter(item, query) {
      return Object.values(item).some(val => {
        if (typeof val == 'string') {
          return String(val).toLowerCase().includes(query.toLowerCase().trim())
        }
      })
    },
    addKeyword() {
      this.$nextTick(() => {
        let keywords = this.kwQuery.trim().split(';')
        keywords.forEach(kw => {
          if (kw !== null || kw !== '' || kw.length > 0) {
            if (!this.isKeyword(kw)) {
              this.addNewKeyword(kw)
              this.$store.dispatch('message/setMessage', {text: `Added new keyword(s): ${kw}`, type:"success"})
              this.record.keywords.push(kw)
            }
          }
        });
        this.$nextTick(() => { this.kwQuery = '' })
      })
    },
    addComment() {
      this.$nextTick(() => {
        if (this.commentInput != null || this.commentInput != '') {
          this.commentInput = this.commentInput.trim()
          this.record.comments.push(...this.commentInput.split(';'))
          this.$nextTick(() => {
            this.commentInput = null
          })
        }
      })
    },
  },
}
</script>

<style scoped>
.v-input {
  margin: 4px;
}
</style>
